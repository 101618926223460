import React from 'react'

const home = [
  {
    icon: "pe-7s-home",
    label: 'Ana Sayfa',
    to: '#/dashboard',
  },
  {
    icon: "pe-7s-user",
    label: 'Kariyer',
    content: [
      {
        icon: "pe-7s-user",
        label: 'Profil',
        to: '#/kisisel-bilgiler',
      },
      {
        icon: "pe-7s-study",
        label: 'Eğitim',
        to: '#/egitimlerim',
      },
      // {
      //   icon: "pe-7s-diamond",
      //   label: 'Tecrübelerim',
      //   to: '#/tecrubeler',
      // },
      // {
      //   icon: "pe-7s-search",
      //   label: 'İş İlanı Ara',
      //   to: '#/is-ara',
      // },
    ]
  },
]

const editor = [
  {
    icon: "pe-7s-news-paper",
    label: 'Yazar',
    content: [
      {
        label: 'Hazırlananlar',
        to: '#/hazirlanan-icerik',
      },
      {
        label: 'Yayındakiler',
        to: '#/yayindaki-icerik',
      },
      {
        label: 'Onay Bekleyenler',
        to: '#/onay-bekleyen-icerik',
      },
      {
        label: 'Silinmişler',
        to: '#/silinen-icerik',
      },
    ]
  },
]

const project = [
  {
    icon: "lnr-magic-wand",
    label: 'Yönetilen Projeler',
    to: '#/yonetilen-projeler',
  },
  {
    icon: "lnr-pencil",
    label: 'Çalışılan Projeler',
    to: '#/calisilan-projeler',
  },
  {
    icon: "lnr-flag",
    label: 'Proje Arşiv',
    to: '#/bitmis-projeler',
  },
  {
    icon: "lnr-star",
    label: 'Yarışmalar',
    to: '#/yarismalar',
  },
  {
    icon: "pe-7s-search",
    label: 'Proje Ara',
    to: '#/proje-ara',
  },

]

const office = [
  {
    icon: "lnr-apartment",
    label: 'Genel Bilgiler',
    to: '#/ofis',
  },
  {
    icon: "lnr-user",
    label: 'Çalışanlar',
    to: '#/calisanlar',
  },
  {
    icon: "pe-7s-rocket",
    label: 'Hizmetler',
    to: '#/hizmetler',
  },
  {
    icon: "pe-7s-rocket",
    label: 'Ürünler',
    to: '#/urunler',
  },
  {
    icon: "pe-7s-way",
    label: 'İş İlanları',
    to: '#/is-ilanlari',
  },
]

export default { home, editor, project, office };
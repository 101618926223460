import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import _nav from "./_myNav";
import { useAtom } from "jotai";
import { userAtom } from "../../_atoms/user";

const Nav = props => {
  const [user] = useAtom(userAtom)
  const toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  const { isEditor } = user

  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Ana Sayfa</h5>
      <MetisMenu content={_nav.home} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
      {isEditor &&
        <MetisMenu content={_nav.editor} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
      }
      <h5 className="app-sidebar__heading">Proje</h5>
      <MetisMenu content={_nav.project} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
      <h5 className="app-sidebar__heading">Ofis</h5>
      <MetisMenu content={_nav.office} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));

import React from "react"

const Schools = React.lazy(() => import('./Pages/Profile/Schools'))
const School = React.lazy(() => import('./Pages/Profile/School'))
const MyWork = React.lazy(() => import('./Pages/Profile/MyWork'))
const MyWorks = React.lazy(() => import('./Pages/Profile/MyWorks'))
const Profile = React.lazy(() => import('./Pages/Profile/Profile'))
const News = React.lazy(() => import('./Pages/News/News'))
const New = React.lazy(() => import('./Pages/News/New'))
const Construction = React.lazy(() => import('./Pages/Construction/Construction'))

const Work = React.lazy(() => import('./Pages/Company/Work/Work'))
const Works = React.lazy(() => import('./Pages/Company/Work/Works'))
const Company = React.lazy(() => import('./Pages/Company/Company'))
const Companies = React.lazy(() => import('./Pages/Company/Companies'))
const Service = React.lazy(() => import('./Pages/Company/Service/Service'))
const Services = React.lazy(() => import('./Pages/Company/Service/Services'))
const BranchProduct = React.lazy(() => import('./Pages/Product/Product'))
const BranchProducts = React.lazy(() => import('./Pages/Product/Products'))
const Worker = React.lazy(() => import('./Pages/Company/Worker/Worker'))
const Workers = React.lazy(() => import('./Pages/Company/Worker/Workers'))
const Promotions = React.lazy(() => import('./Pages/Promotion/Promotions'))
const Promotion = React.lazy(() => import('./Pages/Promotion/Promotion'))
const Home = React.lazy(() => import('./Pages/Home/index'))

const SearcWorks = React.lazy(() => import('./Pages/Profile/SearcWorks'))

const Project = React.lazy(() => import('./Pages/Project/Project'))
const Projects = React.lazy(() => import('./Pages/Project/Projects'))
const SearchProduct = React.lazy(() => import('./Pages/Project/SearchProduct'))

const routes = [
    { path: '/', exact: true, name: 'Archimedii', },

    { path: '/kisisel-bilgiler', name: 'Kişisel Bilgilerim', component: Profile },
    { path: '/egitimlerim', name: 'Eğitimlerim', component: Schools },
    { path: '/egitim-detay', name: 'Eğitim-Detay', component: School },
    { path: '/tecrubeler', name: 'İş Geçmişim', component: MyWorks },
    { path: '/tecrube-detay', name: 'İş Geçmişim Detay', component: MyWork },

    { path: '/hazirlanan-icerik', name: 'Yayındaki Haberler', component: News },
    { path: '/yayindaki-icerik', name: 'Yayındaki Haberler', component: News },
    { path: '/onay-bekleyen-icerik', name: 'Onay Bekleyen Haberler', component: News },
    { path: '/silinen-icerik', name: 'Silinen Haberler', component: News },
    { path: '/icerik-detay', name: 'İçerik Detay', component: New },
    { path: '/yonetilen-projeler', name: 'Yönetilen Projeler', component: Projects },
    { path: '/calisilan-projeler', name: 'Çalışılan Projeler', component: Projects },
    { path: '/bitmis-projeler', name: 'Bitmiş Projeler', component: Projects },
    { path: '/proje-detay', name: 'Proje Detay', component: Project },

    { path: '/dashboard', name: 'Ana Sayfa', component: Construction },
    { path: '/is-ilanlari', name: 'İşler', component: Construction },
    { path: '/is-ilani-detay', name: 'İş Detay', component: Construction },
    { path: '/ofis', name: 'Şube', component: Construction },
    { path: '/ofis-detay', name: 'Şube', component: Construction },
    { path: '/calisanlar', name: 'Şube', component: Construction },
    { path: '/calisan-detay', name: 'Şube', component: Construction },
    { path: '/is-ara', name: 'İş Ara', component: Construction },

    { path: '/hizmetler', name: 'Hizmetler', component: Construction },
    { path: '/hizmet-detay', name: 'Hizmet Detay', component: Construction },
    { path: '/urunler', name: 'Ürünler', component: Construction },
    { path: '/urun-detay', name: 'Ürün Detay', component: Construction },
    { path: '/proje-ara', name: 'Proje Ara', component: Construction },
]

export default routes;